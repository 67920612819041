body {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.container {
  width: 90vw;
  height: 90vh;
  margin: 2rem auto;
}

.row {
  margin: 0 auto 1rem;
}

.col-10 {
  margin: 0 0 0.7rem;
}

.col-8 {
  margin: 0;
}

.col-6.col-sm-6.col-md-6.col-lg-6 {
  min-width: 250px;
  max-width: 400px;
  margin: 0 18px 18px;
}

#logo-name {
  /* display: inline; */
  /* margin: 0 0 1rem; */
}

#logo-name:first-child {
  margin-right: 16px;
}

h4,
h5 {
  font-weight: 600;
  margin: 0 0 12px;
}

h4 {
  color: #a42727;
}

h5 {
  color: #219612;
}

input {
  width: 100%;
  max-width: 380px;
  margin: 0 auto 0.7rem;
  display: block;
  border: 2px solid #ccc;
  border-radius: 7px;
  background-color: #219612;
  color: white;
}

input:focus {
  background-color: #a42727;
  outline: none;
  color: white;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
  text-align: center;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccc;
  text-align: center;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccc;
  text-align: center;
}

#typing {
  margin: 0 0 0.7rem;
  font-size: 0.7rem;
}

button {
  margin: 0 0.7rem 0.5rem;
  width: 15vh;
  text-align: center;
}

.btn.btn-danger {
  background-color: white;
  color: #a42727;
  border: none;
}

.btn.btn-danger:hover {
  background-color: red;
  color: white;
  border: 1px solid #ccc;
}

#list {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 1px 2px #ccc;
  height: 45vh;
  padding: 8px 16px 8px;
  background-color: LimeGreen;
  color: white;
  text-align: left;
  overflow-y: auto;
}

#pList {
  margin: 0;
}

span.spanCreate {
  text-decoration: underline;
  color: black;
}

span.spanEdit {
  text-decoration: underline;
  color: blue;
}

span.spanRemove {
  text-decoration: underline;
  color: #156815;
}

@media (max-width: 520px) {
  #logo-name:first-child {
    margin: 0 auto 0.7rem;
  }

  #logo-name {
    display: block;
    box-sizing: border-box;
    /* margin: 0 auto 1rem; */
  }
}

@media (max-width: 1010px) {
  .col-6.col-sm-6.col-md-6.col-lg-6 {
    max-width: 300px;
  }
}

@media (max-width: 790px) {
  .col-6.col-sm-6.col-md-6.col-lg-6 {
    max-width: 250px;
  }
}

@media (max-width: 767px) {
  .col-6.col-sm-6.col-md-6.col-lg-6 {
    min-width: 80vw;
  }

  #list {
    height: 30vh;
  }
}

@media (max-width: 374px) {
  h4,
  h5 {
    font-size: 16px;
  }
  input,
  p {
    font-size: 14px;
  }
  .btn.btn-warning,
  .btn.btn-danger {
    font-size: 12px;
    padding: 3px;
  }
  .row.justify-content-center {
    margin: 0 0 4px;
  }
  hr {
    margin: 4px 0 6px;
  }
}
